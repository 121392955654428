<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <div v-if="hjemmetjenester.length">
                <div class="caption">Aktive koblinger</div>
                <br />
                <div
                    v-for="(hjemmetjeneste, index) in hjemmetjenester"
                    :key="index"
                    to="/"
                    v-bind:class="{ 'v-card__date': true, 'v-card__date--no-link': true }"
                >
                    <div class="grey lighten-2">
                        <v-icon>mdi-account-multiple</v-icon>
                    </div>
                    <div>
                        <span v-if="hjemmetjeneste.frivillig.id == person.id" style="font-weight: bold">Bruker - {{ hjemmetjeneste.bruker.fulltNavn }}</span>
                        <span v-else-if="hjemmetjeneste.bruker.id == person.id" style="font-weight: bold"
                            >Frivillig - {{ hjemmetjeneste.frivillig.fulltNavn }}</span
                        >
                        <span> {{ hjemmetjeneste.type.navn }} </span>
                    </div>
                </div>
            </div>
            <template v-if="hasPersonRole('BRUKER_HJEMME') && hjemmetjenester.length">
                <v-divider></v-divider>
                <br />
            </template>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="hjemmeinformasjon.typeId"
                            :items="typer"
                            item-value="id"
                            item-text="navn"
                            :rules="rules.typeId"
                            label="Type hjemmetjeneste"
                        ></v-select>
                    </v-col>
                    <template v-if="hasPersonRole('BRUKER_HJEMME')">
                        <v-col cols="12">
                            <v-textarea v-model="hjemmeinformasjon.krav" label="Ønsker/krav" rows="2"></v-textarea>
                        </v-col>
                        <v-col cols="12" v-if="hjemmeinformasjon.typeId == familieTypeId">
                            <v-text-field v-model="hjemmeinformasjon.antallBarn" label="Antall barn"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="hjemmeinformasjon.om" label="Kort om" rows="2"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="hjemmeinformasjon.kommentar" label="Kommentar"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <v-text-field v-model="hjemmeinformasjon.forstegangssamtalePersonId" label="Kartleggingssamtale av"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <date-field v-model="hjemmeinformasjon.forstegangssamtaleDato" label="Samtaledato"></date-field>
                        </v-col>
                    </template>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="changed" color="primary" v-on:click="save">Lagre</v-btn>
            <v-btn text v-if="changed" color="primary" v-on:click="cancel">Angre endringer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: '',
    components: {
        DateField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        const hjemmetjeneste = {};
        const hjemmeinformasjon = {
            typeId: null,
            krav: '',
            antallBarn: '',
            om: '',
            kommentar: '',
            forstegangssamtalePersonId: null,
            forstegangssamtaleDato: null,
        };

        return {
            changed: false,

            person: {},

            hjemmetjenester: [],

            hjemmeinformasjon: hjemmeinformasjon,
            ingenHjemmeinformasjon: Object.assign({}, hjemmeinformasjon),

            hjemmetjeneste: hjemmetjeneste,

            typer: [],
            familieTypeId: '18fdceca-312c-4bed-9934-72fc0d64133f', // Familiehjelperen

            rules: {
                typeId: [(v) => !!v || 'Må oppgis'],
            },
        };
    },
    computed: {
        ...mapGetters('api', ['hasUserRole']),
    },
    watch: {
        value: async function (value) {
            this.person = this.value = value;
            await this.load();
        },
        hjemmeinformasjon: {
            handler: function () {
                this.changed = true;
            },
            deep: true,
        },
    },

    /**
     * created
     */
    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/hjemmetjeneste/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }

        if (this.value) {
            this.person = this.value;
        }

        await this.load();
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson', 'formatVisit']),
        ...mapActions(['snackbar']),

        /**
         * load
         */
        load: async function () {
            if (this.person) {
                this.update();
            }
        },

        /**
         * update
         */
        update: async function () {
            if (this.person) {
                const hjemmetjenester = [];

                // TODO: change API request
                let response = await this.request({
                    method: 'get',
                    url: '/hjemmetjeneste',
                    params: {
                        status: 'aktiv',
                    },
                });

                if (response) {
                    for (let hjemmetjeneste of response) {
                        if (hjemmetjeneste.frivillig.id == this.person.id || hjemmetjeneste.bruker.id == this.person.id) {
                            hjemmetjeneste = await this.formatVisit(hjemmetjeneste);
                            hjemmetjenester.push(hjemmetjeneste);
                        }
                    }
                }

                this.hjemmetjenester = hjemmetjenester;
            }

            if (this.person && this.person.hjemmeinformasjon) {
                for (const field in this.hjemmeinformasjon) {
                    this.hjemmeinformasjon[field] = this.person.hjemmeinformasjon[field];
                }
                if (this.person.hjemmeinformasjon.type) {
                    this.hjemmeinformasjon.typeId = this.person.hjemmeinformasjon.type.id;
                }

                if (!this.hjemmeinformasjon.kommentar && this.hjemmeinformasjon.henvistAv) {
                    this.hjemmeinformasjon.kommentar = this.hjemmeinformasjon.henvistAv;
                    this.hjemmeinformasjon.henvistAv = null;
                }
            } else {
                for (const field in this.hjemmeinformasjon) {
                    this.hjemmeinformasjon[field] = this.ingenHjemmeinformasjon[field];
                }
            }
            window.setTimeout(() => {
                this.changed = false;
                this.$refs.form.resetValidation();
            }, 100);
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                try {
                    let person = null;

                    if (this.changed) {
                        const data = Object.assign({}, this.hjemmeinformasjon);
                        for (const field of ['forstegangssamtaleDato']) {
                            if (data[field]) {
                                data[field] = data[field].format('DD.MM.YYYY');
                            }
                        }

                        const response = await this.request({
                            method: 'post',
                            url: '/person/' + this.person.id + '/hjemmeinformasjon',
                            data: data,
                        });

                        if (response && typeof response.id != 'undefined') {
                            person = response;
                        }
                    }

                    // success
                    if (person) {
                        this.person = await this.formatPerson(person);
                        this.update();

                        this.$emit('input', this.person);

                        this.snackbar('Lagret');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },

        /**
         * cancel
         */
        cancel: async function () {
            await this.update();
            this.changed = false;
        },

        /**
         * hasPersonRole
         */
        hasPersonRole: function (rolle) {
            return this.person.roller && this.person.roller.filter((r) => r.id == rolle).length > 0;
        },
    },
};
</script>
<style lang="scss"></style>
